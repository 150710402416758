import React from 'react';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { RichText } from 'prismic-reactjs';

import htmlSerializer from '../../utils/htmlSerializer';

const WistiaVideo = loadable(() =>
  pMinDelay(import('../common/WistiaVideo'), 2000)
)

const pStyle = "text-base md:text-xl mb-6"

const StyledDiv = styled.div`
  min-height: 460px;
`

const IntroHomeNew = ({ agentPage }) => {
  const data = useStaticQuery(graphql`
    query IntroHomeNewNewQuery {
      prismicHomePage {
        data {
          intro_section_title
          wistia_id
          intro_section_text {
            richText
          }
          intro_section_text_agent {
            richText
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePage

  return (
    <div className="bg-site-green-light pt-4 md:pt-24 2xl:pt-32 3xl:pt-56 pb-12 md:pb-24">
      <StyledDiv className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white  mx-auto p-8 rounded-3xl shadow-lg">
        <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto">
          <h2 className="text-white text-base md:text-2xl font-bold text-center">{doc.data.intro_section_title ? doc.data.intro_section_title : "Section Title"}</h2>
        </div>

        <div className="w-full max-w-2xl mt-12 text-center">
          {agentPage
            ?
            doc.data.intro_section_text_agent.richText
              ?
              <RichText render={doc.data.intro_section_text_agent.richText} htmlSerializer={htmlSerializer} />
              :
              <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            :
            doc.data.intro_section_text.richText
              ?
              <RichText render={doc.data.intro_section_text.richText} htmlSerializer={htmlSerializer} />
              :
              <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          }
        </div>

        <div className="w-11/12 lg:w-10/12 max-w-screen-md mx-auto shadow-lg mt-6 mb-6">
          <WistiaVideo wistiaID={doc.data.wistia_id} />
        </div>
      </StyledDiv>
    </div>
  )
}

IntroHomeNew.propTypes = {
  agentPage: PropTypes.bool,
}

export default IntroHomeNew